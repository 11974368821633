import { API } from "./api";

/**
 * Retrieves suggested changes for a given system.
 *
 * @async
 * @param {number} [integratedSystemId=null] - The ID of the integrated system.
 * @param {boolean} [appliedChangesOnly=false] - Specifies whether to retrieve only applied changes.
 * @param {boolean} [pendingChangesOnly=false] - Specifies whether to retrieve only pending changes.
 * @param {boolean} [rejectedChangesOnly=false] - Specifies whether to retrieve only rejected changes.
 * @param {string} [changesType=null] - The type of changes to retrieve.
 * @param {string} [dataPoint=null] - The data point to retrieve changes for.
 * @param {boolean} [isDataProject=false] - Specifies whether the data point is a project.
 * @returns {Promise} - A promise that resolves with the retrieved changes.
 */
export const getSuggestedChangesForSystem = async (
  integratedSystemId: number = null,
  appliedChangesOnly?: boolean,
  pendingChangesOnly?: boolean,
  rejectedChangesOnly?: boolean,
  changesType?: string,
  dataPoint?: string,
  isDataProject?: boolean
) => {
  const response = await API.get("/get-suggested-changes", {
    params: {
      integrated_system_id: isDataProject ? null : integratedSystemId,
      applied_changes_only: appliedChangesOnly,
      pending_changes_only: pendingChangesOnly,
      rejected_changes_only: rejectedChangesOnly,
      changes_type: changesType,
      data_point: dataPoint,

      data_project_id: isDataProject ? integratedSystemId : null,
    },
  });
  return response.data;
};

/**
 * Retrieves the summary of suggested changes for a system.
 *
 * @param {number} [integratedSystemId=null] - The ID of the integrated system. Defaults to null if no value is specified.
 * @param {boolean} [appliedChangesOnly] - Indicates if only applied changes should be included. Defaults to undefined if no value is specified.
 * @param {boolean} [pendingChangesOnly] - Indicates if only pending changes should be included. Defaults to undefined if no value is specified.
 * @param {boolean} [rejectedChangesOnly] - Indicates if only rejected changes should be included. Defaults to undefined if no value is specified.
 * @param {string} [changesType] - The type of changes to include. Defaults to undefined if no value is specified.
 * @param {boolean} [isDataProject] - Indicates if the system is a data project. Defaults to undefined if no value is specified.
 * @returns {Promise} - A Promise that resolves with the requested summary of suggested changes.
 */
export const getSuggestedChangesSummaryForSystem = async (
  integratedSystemId: number = null,
  appliedChangesOnly?: boolean,
  pendingChangesOnly?: boolean,
  rejectedChangesOnly?: boolean,
  changesType?: string,
  isDataProject?: boolean
) => {
  const response = await API.get("/get-suggested-changes-summary", {
    params: {
      integrated_system_id: isDataProject ? null : integratedSystemId,
      applied_changes_only: appliedChangesOnly,
      pending_changes_only: pendingChangesOnly,
      rejected_changes_only: rejectedChangesOnly,
      changes_type: changesType,
      data_project_id: isDataProject ? integratedSystemId : null,
    },
  });
  return response.data;
};

/**
 * Performs a suggested change transition.
 *
 * @param {number[]} changeIds - An array of change IDs to perform the transition on.
 * @param {boolean} [negativeTransition] - Optional. If set to true, performs a negative transition. Defaults to false.
 * @returns {Promise} - A promise that resolves with the response data from the API request.
 */
export const transitionSuggestedChange = async (
  changeIds: number[],
  negativeTransition?: boolean
) => {
  const response = await API.post("/transition-suggested-change", {
    suggested_changes_ids: changeIds,
    negative_transition: negativeTransition ?? false,
  });
  return response.data;
};

/**
 * Approves a suggested change.
 *
 * @param {number} changeId - The ID of the suggested change to approve.
 * @returns {Promise<any>} A promise that resolves with the response data.
 */
export const approveSuggestedChange = async (changeId: number) => {
  const response = await API.post("/approve-suggested-change", {
    suggested_change_id: changeId,
  });
  return response.data;
};

/**
 * Approves a suggested change choice for a given change ID and choice ID.
 * @param {number} changeId - The ID of the suggested change.
 * @param {string} choiceId - The ID of the choice to be approved.
 * @returns {Promise<any>} - A promise that resolves with the response data.
 */
export const approveMultipleChange = async (
  changeId: number,
  choiceId: string
) => {
  const response = await API.post("/approve-suggested-change-choice", {
    suggested_change_id: changeId,
    choice_id: choiceId,
  });
  return response.data;
};

/**
 * Approves an ad hoc suggested change.
 *
 * @param {string} fieldName - The name of the field being changed.
 * @param {string} newValue - The new value for the field.
 * @param {string} oldValue - The old value for the field.
 * @param {number} originatingSuggestedChangeId - The ID of the originating suggested change.
 *
 * @returns {Promise} - A promise that resolves to the response data upon successful approval of the suggested change.
 */
export const approveAdHocSuggestedChange = async (
  fieldName: string,
  newValue: string,
  oldValue: string,
  originatingSuggestedChangeId: number,
  crmId: string
) => {
  const response = await API.post("/approve-adhoc-change", {
    field_name: fieldName,
    new_value: newValue,
    old_value: oldValue,
    originating_suggested_change_id: originatingSuggestedChangeId,
    crm_id: crmId,
  });
  return response.data;
};

/**
 * Rolls back a suggested change by sending a POST request to the "/rollback_suggested_change" endpoint.
 *
 * @param {number} changeId - The ID of the suggested change to be rolled back.
 * @returns {Promise} - A Promise that resolves to the data returned from the server.
 */
export const rollbackSuggestedChange = async (changeId: number) => {
  const response = await API.post("/rollback_suggested_change", {
    suggested_change_id: changeId,
  });
  return response.data;
};

/**
 * Accepts suggested changes with modifications.
 *
 * @param {number} changeId - The ID of the suggested change.
 * @param {string} newValue - The new value for the suggested change.
 * @returns {Promise} - A Promise that resolves to the response data.
 */
export const acceptSuggestedChangesWithModifications = async (
  changeId: number,
  newValue: string
) => {
  const response = await API.post("/approve-suggested-with-modifications", {
    suggested_change_id: changeId,
    new_value: newValue,
  });
  return response.data;
};

/**
 * Retrieves the CRM contact for the suggested change with the given ID.
 * @async
 * @param {number} changeId - The ID of the suggested change.
 * @returns {Promise<Object>} - A Promise that resolves to the CRM contact object.
 */
export const getCRMContactForSuggestedChange = async (changeId: number) => {
  const response = await API.get("/get-crm-contact-for-suggested-change", {
    params: { suggested_change_id: changeId },
  });
  return response.data;
};

/**
 * Retrieves the CRM URL for a suggested change with the given changeId.
 *
 * @param {number} changeId - The ID of the suggested change.
 * @returns {Promise<string>} - A promise that resolves to the CRM URL.
 */
export const getCRMURLForSuggestedChange = async (changeId: number) => {
  const response = await API.get("/get-crm-url-for-suggested-change", {
    params: { suggested_change_id: changeId },
  });
  return response.data;
};

/**
 * Fetches the explanation for a suggested change.
 *
 * @async
 * @param {number} changeId - The ID of the suggested change.
 * @param {number} batchId - The ID of the batch.
 * @returns {Promise} - A promise that resolves with the explanation for the suggested change.
 */
export const getExplainForSuggestedChange = async (
  changeId: number,
  batchId: number,
  recordId: number
) => {
  const response = await API.get("/get-suggested-change-explain", {
    params: {
      suggested_change_id: changeId,
      batch_id: batchId,
      record_id: recordId,
    },
  });
  return response.data;
};

/**
 * Retrieves the written changes metrics from the server.
 *
 * @async
 * @param {number} [integratedSystemId] - The ID of the integrated system.
 * @param {number} [dataProjectId] - The ID of the data project.
 * @returns {Promise} A promise that resolves to the written changes metrics data.
 */
export const getWrittenChangesMetrics = async (
  integratedSystemId?: number,
  dataProjectId?: number
) => {
  const response = await API.get("get-written-changes-metrics", {
    params: {
      integrated_system_id: integratedSystemId,
      data_project_id: dataProjectId,
    },
  });
  return response.data;
};
