import { agDateColumnFilterComparator, toReadableText } from "../utils/utils";

export const getSuggestedChangesColumnData = (suggestedChanges) => {
  let computedColumns: any[] = [];
  if (!suggestedChanges) {
    return [];
  }

  computedColumns.push({
    field: "row_id",
    headerName: "Row #",
    sortable: false,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    suppressMovable: true,
    valueGetter: "node.rowIndex + 1",
  });

  const commonAttrs = {
    suppressSizeToFit: false,
    sortable: true,
    floatingFilter: true,
    filter: true,
    resizable: true,
  };
  computedColumns.push({
    ...commonAttrs,
    field: "company",
    headerName: "Company",
  });
  computedColumns.push({
    field: "old_value",
    headerName: "Old Value",
    cellRenderer: "valueChangeRenderer",
    ...commonAttrs,
  });
  computedColumns.push({
    field: "new_value",
    headerName: "New Value",
    cellRenderer: "valueChangeRenderer",
    ...commonAttrs,
  });
  computedColumns.push({
    ...commonAttrs,
    field: "probability",
    headerName: "Probability",
    sort: "desc",
    suppressSizeToFit: true,
    cellRenderer: "probabilityCellRenderer",
    filter: "agNumberColumnFilter",
  });

  computedColumns.push({
    field: "company",
    headerName: "Google Search URL",
    cellRenderer: "googleSearchRenderer",
    hide: true,
    ...commonAttrs,
  });

  computedColumns.push({
    field: "company",
    headerName: "LinkedIn Search URL",
    cellRenderer: "linkedinSearchRenderer",
    hide: true,
    ...commonAttrs,
  });

  for (const [key, _] of Object.entries(suggestedChanges)) {
    if (
      [
        "data_loader_config_revision_id",
        "record_id",
        "id",
        "batch_id",
        "description",
        "probability",
        "clazz",
        "vip_contact",
        "contact_manager",
        "value_diff",
        "company",
        "old_value",
        "new_value",
        "crm_contact_id",
        "contact_first_seen",
        "last_email_hard_bounce",
        "last_email_soft_bounce",
        "last_email_activity",
        "known_by",
      ].indexOf(key) < 0
    ) {
      let cellRenderer = null;
      if (
        ["change_status", "change_type", "change_instigator"].indexOf(key) > -1
      ) {
        cellRenderer = "humanReadableFormatRenderer";
      }
      computedColumns.push({
        field: key,
        headerName: toReadableText(key),
        ...commonAttrs,
        hide:
          [
            "change_status",
            "change_type",
            "change_instigator",
            "country",
            "state_or_province",
            "city",
            "middle_name",
          ].indexOf(key) > -1,
        cellRenderer: cellRenderer,
        headerCheckboxSelection: (params) =>
          params.columnApi.getAllDisplayedColumns()[0] === params.column,
        checkboxSelection: (params) =>
          params.columnApi.getAllDisplayedColumns()[0] === params.column,
        floatingFilter: true,
      });
    }
  }

  computedColumns.push({
    field: "description",
    headerName: "Description",
    hide: true,
    ...commonAttrs,
  });
  computedColumns.push({
    field: "id",
    headerName: "ID",
    hide: true,
    ...commonAttrs,
  });
  computedColumns.push({
    field: "crm_contact_id",
    headerName: "CRM ID",
    hide: true,
    ...commonAttrs,
  });
  computedColumns.push({
    field: "known_by",
    headerName: "Known By",
    hide: true,
    ...commonAttrs,
  });
  computedColumns.push({
    field: "contact_first_seen",
    headerName: "Contact First Seen",
    ...commonAttrs,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: agDateColumnFilterComparator,
    },
    hide: true,
  });
  computedColumns.push({
    field: "last_email_activity",
    headerName: "Last Email Activity",
    suppressSizeToFit: false,
    sortable: true,
    floatingFilter: true,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: agDateColumnFilterComparator,
    },
  });
  computedColumns.push({
    field: "last_email_hard_bounce",
    headerName: "Last Email Hard Bounce",
    ...commonAttrs,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: agDateColumnFilterComparator,
    },
  });
  computedColumns.push({
    field: "last_email_soft_bounce",
    headerName: "Last Email Soft Bounce",
    ...commonAttrs,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: agDateColumnFilterComparator,
    },
  });
  computedColumns.push({
    field: "crm_contact_id",
    headerName: "Database Id",
    ...commonAttrs,
    hide: true,
    cellRenderer: "crmDatabaseIdRenderer",
  });

  return computedColumns;
};

export const categorizeSuggestedChanges = (suggestedChanges) => {
  let newAllPending = 0;
  let newFillInBlank = 0;
  let newCorrection = 0;
  let newInvalidData = 0;
  let newActioned = 0;
  if (suggestedChanges?.rows?.length > 0) {
    suggestedChanges.rows.forEach((item) => {
      if (
        item.change_status === "VALIDATED" ||
        item.change_status === "HUMAN_CONFIRMED"
      ) {
        newActioned++;
      } else if (item.change_status !== "VALIDATED") {
        newAllPending++;
      }
      if (item.change_type === "FILL_IN_BLANK") {
        newFillInBlank++;
      } else if (item.change_type === "CORRECTION") {
        newCorrection++;
      } else if (item.change_type === "INVALID_DATA") {
        newInvalidData++;
      }
    });
  }
  return [
    newAllPending,
    newFillInBlank,
    newCorrection,
    newInvalidData,
    newActioned,
  ];
};

function fillInOccurrence(chartData: { data: any[]; labels: any[] }, item) {
  if (chartData.labels.indexOf(toReadableText(item.field)) > -1) {
    chartData.data[chartData.labels.indexOf(toReadableText(item.field))] += 1;
  } else {
    chartData.labels.push(toReadableText(item.field));
    chartData.data.push(1);
  }
}

export const getSuggestedChangesStats = (suggestedChanges) => {
  let blanksChartData = { labels: [], data: [] };
  let correctionsChartData = { labels: [], data: [] };
  let allChangesChartData = { labels: [], data: [] };

  suggestedChanges.forEach((item) => {
    if (item.change_type === "FILL_IN_BLANK") {
      fillInOccurrence(blanksChartData, item);
    }
    if (item.change_type === "CORRECTION") {
      fillInOccurrence(correctionsChartData, item);
    }
    if (
      allChangesChartData.labels.indexOf(toReadableText(item.change_type)) > -1
    ) {
      allChangesChartData.data[
        allChangesChartData.labels.indexOf(toReadableText(item.change_type))
      ] += 1;
    } else {
      allChangesChartData.labels.push(toReadableText(item.change_type));
      allChangesChartData.data.push(1);
    }
  });

  return [blanksChartData, correctionsChartData, allChangesChartData];
};
