import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import React, { useEffect } from "react";

import { ConfigSidebarItem } from "./SidebarItem";
import { useLocation } from "react-router-dom";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import Box from "@mui/material/Box";
import ListSubheader from "@mui/material/ListSubheader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../theme/theme";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import { CirromLogo } from "../Logo";
import KeyIcon from "@mui/icons-material/Key";
import SupportIcon from "@mui/icons-material/Support";
import NotificationsIcon from "@mui/icons-material/Notifications";

export interface SidebarProps {
  onMobileClose?: any;
  openMobile?: any;
  userProfile?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    subHeader: {
      textTransform: "uppercase",
      padding: 0,
      margin: 0,
      color: "black",
      fontWeight: "bold",
    },
  })
);

export const ConfigSidebar = (props: Readonly<SidebarProps>) => {
  const location = useLocation();
  const { onMobileClose, openMobile } = props;
  const classes = useStyles();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const drawerContent = (
    <>
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CirromLogo />
      </Box>
      <Divider style={{ color: "white" }} />
      <Box
        style={{
          padding: "10px",
        }}
      >
        <List>
          <ConfigSidebarItem
            location="/app/dashboard"
            Icon={HomeOutlinedIcon}
            title="Home"
            isGroup={false}
          />
          <Divider style={{ backgroundColor: "white" }} />
          <ListSubheader className={classes.subHeader} disableSticky>
            General
          </ListSubheader>
          <ConfigSidebarItem
            location="/app/configure/users"
            Icon={PersonOutlinedIcon}
            title="User Management"
            isGroup={false}
          />
          <Divider style={{ backgroundColor: "white" }} />
          <ListSubheader className={classes.subHeader} disableSticky>
            Technical
          </ListSubheader>
          {["CIRROM_ADMIN", "TECH_ADMIN", "SUPER_ADMIN"].includes(
            props.userProfile
          ) && (
            <ConfigSidebarItem
              location="/app/configure/batch"
              Icon={IntegrationInstructionsOutlinedIcon}
              title="Data Sources"
              isGroup={false}
            />
          )}

          <ConfigSidebarItem
            location={"/app/configure/system-rules"}
            Icon={RuleOutlinedIcon}
            title={"Data Sources Rules"}
            isGroup={false}
          />

          {["CIRROM_ADMIN", "TECH_ADMIN", "SUPER_ADMIN"].includes(
            props.userProfile
          ) && (
            <ConfigSidebarItem
              location="/app/configure/secrets"
              Icon={KeyIcon}
              title="Secrets & Preferences"
              isGroup={false}
            />
          )}
          {["CIRROM_ADMIN", "TECH_ADMIN", "SUPER_ADMIN"].includes(
            props.userProfile
          ) && (
            <ConfigSidebarItem
              location="/app/configure/support"
              Icon={SupportIcon}
              title="Support"
              isGroup={false}
            />
          )}
          {["CIRROM_ADMIN", "TECH_ADMIN", "SUPER_ADMIN"].includes(
            props.userProfile
          ) && (
            <ConfigSidebarItem
              location="/app/configure/data-project-alerts"
              Icon={NotificationsIcon}
              title="Data Alerts"
              isGroup={false}
            />
          )}
        </List>
      </Box>
    </>
  );
  return (
    <>
      <Drawer
        anchor="left"
        variant="temporary"
        open={openMobile}
        onClose={onMobileClose}
        PaperProps={{
          style: { width: 240, height: "100%" },
        }}
        style={{ display: lgUp ? "none" : "inherit" }}
      >
        {drawerContent}
      </Drawer>

      <Drawer
        anchor="left"
        variant="persistent"
        open
        PaperProps={{
          style: { width: 240, height: "100%" },
        }}
        style={{ display: lgUp ? "inherit" : "none" }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};
